<template>
    <router-link to="/" class="splash-screen">
        <img src="@/assets/img/logo/splash.png" alt="">
    </router-link>
</template>

<script>
export default {

}
</script>

<style>

</style>