var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('Loading'):_vm._e(),(_vm.dataAvailable)?_c('div',[(_vm.detailRekanan !== undefined)?_c('div',{staticClass:"page-section"},[_c('div',{staticClass:"container mb-4"},[_c('div',{staticClass:"maps"},[_c('GmapMap',{ref:"mapRef",staticStyle:{"width":"100%","height":"400px"},attrs:{"options":{
                        zoomControl: true,
                        mapTypeControl: false,
                        scaleControl: true,
                        streetViewControl: false,
                        rotateControl: true,
                        fullscreenControl: false,
                        disableDefaultUi: false
                        },"center":_vm.center,"zoom":18}},[_c('GmapMarker',{attrs:{"position":_vm.center}})],1),_c('div',{staticClass:"route-maps"},[_c('a',{staticClass:"btn btn-maps p-2",attrs:{"href":'https://maps.google.com/maps?q='+ _vm.detailRekanan.latitude + ',' + _vm.detailRekanan.longitude,"target":"_blank"}},[_c('i',{staticClass:"fas fa-directions mr-1 text-color-green"}),_vm._v("Dapatkan Rute ")])])],1),_c('div',{staticClass:"rekanan-list my-3"},[_c('div',{staticClass:"text-subheading text-color-blue text-uppercase"},[_vm._v(_vm._s(_vm.detailRekanan.name))]),_c('div',{staticClass:"text-description text-uppercase my-2"},[_vm._v(_vm._s(_vm.detailRekanan.address_complete))]),_c('div',{staticClass:"text-description text-uppercase"},[_vm._v("Telp : "+_vm._s(_vm.detailRekanan.phone1))])])])]):_vm._e()]):_vm._e(),(!_vm.dataAvailable && !_vm.loading)?_c('div',[_c('NoData')],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }