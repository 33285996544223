<template>
    <div>
        <section class="space-top">
            <!-- <div class="container">
                <div class="row align-items-center loyalty-points">
                    <div class="col-4 text-loyalty">
                        <div class="text text-color-blue">
                            Loyalty Points Anda
                        </div>
                    </div>
                    <div class="col-8 d-flex align-items-center justify-content-around amount-loyalty">
                        <div class="amount text-white">
                            Rp. 12.890.500
                        </div>
                        <div class="redeem-box">
                            <i class="far fa-star"></i>
                            Redeem
                        </div>
                    </div>
                </div>
            </div> -->
            <img 
            src="@/assets/img/banner.png" class="mt-3 w-100" alt="logo-salute">
        </section>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>