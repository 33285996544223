<template>
  <div class="header width-control text-center">
    <img src="@/assets/img/logo/logo.png" alt="logo-salute">
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>