<template>
  <div>
      <Splash/>
  </div>
</template>

<script>
import Splash from "@/components/SplashScreen.vue"

export default {
    components: {
        Splash
    }
}
</script>

<style>

</style>