var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-section"},[_c('div',{staticClass:"container"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"hubungi-kami mb-5"},[_c('div',{staticClass:"text-subheading text-color-blue mb-4"},[_vm._v("Hubungi Langsung")]),_c('div',{staticClass:"contact d-md-flex"},[_c('button',{staticClass:"btn-green d-flex justify-content-center mb-sm-0 mb-2 mx-sm-2",on:{"click":_vm.copyPhone}},[_c('i',{staticClass:"fas fa-phone-alt fa-lg mr-2"}),_c('span',[_vm._v("CS 24/7")])]),_c('button',{staticClass:"btn-green d-flex justify-content-center mb-sm-0 mb-2 mx-sm-2",on:{"click":_vm.copyEmail}},[_c('i',{staticClass:"far fa-envelope fa-lg mr-2"}),_c('span',[_vm._v("Email")])]),_vm._m(5)])])]),_c('b-modal',{ref:"attention",attrs:{"id":"attention","hide-header":"","hide-footer":"","centered":""}},[_c('div',{staticClass:"text-center text-description text-color-blue m-4"},[_vm._v(" Detail kontak berhasil di copy ke clipboard. ")]),_c('b-button',{staticClass:"color-blue mt-3",attrs:{"block":""},on:{"click":function($event){return _vm.$bvModal.hide('attention')}}},[_vm._v("Oke")])],1),_c('input',{ref:"copyTextPhone",staticClass:"text-right",staticStyle:{"display":"flex","bottom":"0%","z-index":"-99999","height":"0px","border":"none","color":"transparent"},attrs:{"type":"text","value":"02156953505"}}),_c('input',{ref:"copyTextEmail",staticClass:"text-right",staticStyle:{"display":"flex","bottom":"0%","z-index":"-99999","height":"0px","border":"none","color":"transparent"},attrs:{"type":"text","value":"cs@salvus.co.id"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"salvus-logo"},[_c('img',{attrs:{"src":require("@/assets/img/logo/salvus_health.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hubungi-kami mt-5"},[_c('div',{staticClass:"text-subheading text-color-blue mb-2"},[_vm._v("Alamat")]),_c('div',{staticClass:"text-description my-1"},[_vm._v("PT Salvus Prima Perkasa (Salvus Health)")]),_c('div',{staticClass:"text-description"},[_vm._v("Jl. Tomang Raya No. 47F, Tomang, Grogol Petamburan, Jakarta Barat, Jakarta 11440")]),_c('a',{staticClass:"text-color-blue text-note py-2",attrs:{"href":"https://maps.google.com/maps?q=-6.175522210967923,106.80180611519579","target":"_blank"}},[_c('i',{staticClass:"fas fa-directions mr-1 text-color-green"}),_vm._v("Dapatkan Rute ")]),_c('hr')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hubungi-kami"},[_c('div',{staticClass:"text-subheading text-color-blue mb-2"},[_vm._v("No Telepon Kantor")]),_c('div',{staticClass:"text-description my-1"},[_vm._v("+62 21 566 6909")]),_c('hr')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hubungi-kami"},[_c('div',{staticClass:"text-subheading text-color-blue mb-2"},[_vm._v("Fax")]),_c('div',{staticClass:"text-description my-1"},[_vm._v("+62 21 567 1555")]),_c('hr')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hubungi-kami"},[_c('div',{staticClass:"text-subheading text-color-blue mb-2"},[_vm._v("Website")]),_c('a',{staticClass:"text-description my-1",attrs:{"href":"https://salvushealth.id/","target":"_blank"}},[_vm._v("Salvus Health")]),_c('hr')])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"btn-green d-flex justify-content-center mb-sm-0 mb-2 mx-sm-2",attrs:{"href":"https://api.whatsapp.com/send?phone=6285179886909&text=Halo Salvus Health","target":"_blank"}},[_c('i',{staticClass:"fab fa-whatsapp fa-lg mr-2"}),_c('span',[_vm._v("WhatsApp")])])
}]

export { render, staticRenderFns }