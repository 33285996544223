import { render, staticRenderFns } from "./Rekanan.vue?vue&type=template&id=544b23aa"
import script from "./Rekanan.vue?vue&type=script&lang=js"
export * from "./Rekanan.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports